import React, { useEffect, useState } from "react";
import UseFireBase from "../../Hooks/UseFireBase";
import ListDeliveries from "./ListDeliveries";
import "./Style.css";

export default function Deliveries({ select }) {
  const { getData } = UseFireBase();
  const [data, setData] = useState(false);
  const [deliveries, setDeliveries] = useState(false);

  const getDataDeliveries = async () => {
    const dataDeliveries = await getData("deliveries");
    const dataProducts = await getData("products");
    const dataUsers = await getData("users");

    setData({ dataDeliveries, dataProducts, dataUsers });
  };

  useEffect(() => {
    getDataDeliveries();
  }, []);

  useEffect(() => {
    if (data) {
      const join = data.dataDeliveries.map((delivery) => {
        const u = data.dataUsers.find(
          (user) => user.id === delivery.data.id_user
        );
        const p = delivery.data.ids_products.map((id_product) =>
          data.dataProducts.find((product) => product.id === id_product)
        );

        return {
          user: u,
          products: p,
          id: delivery.id,
          delivery: delivery.data
        };
      });
      setDeliveries(join);
    }
  }, [data]);

  return (
    <div className="principal">
      <ListDeliveries data={deliveries} getDataDeliveries={getDataDeliveries} />
    </div>
  );
}
