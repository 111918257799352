import Login from "../Components/Login";
import Dashboard from "../Views/Dashboard";
import RoutePrivate from "../Views/RoutePrivate";
import ViewDelivery from "../Views/ViewDelivery";
import ViewsUser from "../Views/ViewsUser";
import ViewAdvisor from "../Views/ViewAdvisor"
import ViewProduct from "../Views/ViewProduct";
import ViewDeliveryUser from "../Views/ViewDeliveryUser";

export const routes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <RoutePrivate component={Dashboard} />,
  },
  {
    path: "/private",
    element: <RoutePrivate component={Dashboard} />,
  },
  {
    path: "/advisor",
    element: <RoutePrivate component={ViewAdvisor} />,
  },
  {
    path: "/deliveries",
    element: <RoutePrivate component={ViewDelivery} />,
  },
  {
    path: "/user",
    element: <RoutePrivate component={ViewsUser} />,
  },
  {
    path: "/products",
    element: <RoutePrivate component={ViewProduct} />,
  },
  {
    path: "/delivery",
    element: <ViewDeliveryUser  />,
    children: [
      {
        path: "user/:uid",
        element: <ViewDeliveryUser />,
      },
    ],
  },
];
