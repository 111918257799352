import React, { useEffect, useState } from "react";
import UseFireBase from "../../Hooks/UseFireBase";
import ListProducts from "./ListProducts";
import NewProduct from "./NewProduct";

function Product() {
  const { getData } = UseFireBase();
  const [selected, setSelected] = useState("ListProducts");
  const [data, setData] = useState(false);

  const getDataProducts = async () => {
    const dataProducts = await getData("products");
    setData(dataProducts);
  };
  useEffect(() => {
    getDataProducts();
  }, []);

  return (
    <div className="principal">
      {selected === "ListProducts" && (
        <ListProducts setSelected={setSelected} data={data} />
      )}
      {selected === "NewProduct" && (
        <NewProduct setSelected={setSelected} setData={setData} />
      )}
    </div>
  );
}

export default Product;
