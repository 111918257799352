import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { dataUserContext } from "../../../Context/UserContext";
import UseFireBase from "../../../Hooks/UseFireBase";
import "./Style.css";

export default function ListDeliveries({
  setSelected,
  data,
  setIdSelected,
  getDataDeliveries,
}) {
  const { dataUser } = useContext(dataUserContext);
  const { deleteDocWithId } = UseFireBase();
  const [deliveries, setDeliveries] = useState(false);
  const [search, setSearch] = useState("");

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };
  console.log(data);
  const filterDeliveries = () => {
    const dataDeliveries = [...data];
    const filter = dataDeliveries.filter(
      (delivery) =>
        delivery.user.data.name.toUpperCase().includes(search.toUpperCase()) ||
        delivery.delivery.name_wirehouse
          .toUpperCase()
          .includes(search.toUpperCase()) ||
        delivery.delivery.phone_wirehouse
          .toUpperCase()
          .includes(search.toUpperCase())
    );
    setDeliveries(filter);
  };

  function deleteData(data) {
    Swal.fire({
      title: "Deseas Eliminar la entrega",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteDocWithId("deliveries", data.id).then(() => {
          Swal.fire("Se elimino con exito", "", "success").then(() => {
            getDataDeliveries();
          });
        });
      } else if (result.isDenied) {
        Swal.fire("Hubo un Error", "", "info");
      }
    });
  }

  useEffect(() => {
    if (search !== "") filterDeliveries();
    if (deliveries && search === "") setDeliveries(data);
  }, [search]);

  useEffect(() => {
    setDeliveries(data);
  }, [data]);

  return (
    <div className="principal">
      <div className="filter">
        <div className="filter-box">
          <i className="fa-sharp fa-solid fa-filter"></i>
          <input
            type="text"
            name="deliveries"
            onChange={handleChangeSearch}
          ></input>
        </div>
      </div>
      {deliveries ? (
        <div className="table">
          <table className="table-responsive">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Asesor</th>
                <th scope="col">Productos</th>
                <th scope="col">Negocio</th>
                <th scope="col">Mostrador</th>
                <th scope="col">Teléfono</th>
                <th scope="col">Dirección</th>
                <th scope="col">Fecha</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {deliveries &&
                deliveries.map((deliveries, i) => {
                  try {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {deliveries.user !== undefined &&
                            deliveries.user.data.name}
                        </td>
                        <td>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                          >
                            {deliveries.products.map((product) => (
                              <option key={product.id}>
                                {product.data.name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>{deliveries.delivery.name_wirehouse}</td>
                        <td>{deliveries.delivery.name_counter}</td>
                        <td>{deliveries.delivery.phone_counter}</td>
                        <td>{deliveries.delivery.direction_wirehouse}</td>
                        <td>{deliveries.delivery.date}</td>{" "}
                        <td>
                          <div className="button-actions">
                            <i className="fa-sharp fa-solid fa-pen-to-square"></i>

                            <i
                              className="fa-solid fa-people-carry-box"
                              onClick={() => {
                                setIdSelected(deliveries.id);
                                setSelected("NewDeliveries");
                              }}
                            ></i>

                            <i
                              className="fa-solid fa-trash"
                              onClick={() => deleteData(deliveries)}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    );
                  } catch (error) {
                    console.error(error);
                  }
                })}
            </tbody>
          </table>
        </div>
      ) : (
        "Cargando..."
      )}
    </div>
  );
}
