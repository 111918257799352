import React, { useEffect, useState } from "react";
import logo from "../../resources/legrand-logo.jpeg";
import { useParams } from "react-router-dom";
import "./index.css";
import UseFireBase from "../../Hooks/UseFireBase";

function LateralBarUser() {
  const { uid } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [dataUser, setdataUser] = useState(false);
  const { getForId } = UseFireBase();

  const getDatas = async () => {
    const dataAdv = await getForId("users", uid);
    setdataUser(dataAdv);
  };

  useEffect(() => {
    getDatas();
  }, []);

  try {
    return (
      <nav className="bar-user">
        <ul>
          <li>
            <div className="logo-user">
              <img src={logo} style={{ width: "20%" }} />
            </div>
          </li>
          <li>
            {dataUser ? (
              <div className="logo">
                <h5>
                  Bienvenid@ {dataUser && dataUser.name}{" "}
                  {dataUser && dataUser.lastname}
                </h5>
              </div>
            ) : (
              "Cargando..."
            )}
          </li>
        </ul>
      </nav>
    );
  } catch (error) {
    console.log(error);
  }
}

export default LateralBarUser;
