import React, { useRef, useState } from "react";

function AddImages({ addimage, images, setImages, open }) {
  const [scrollAction, setScrollAction] = useState({ prev: 100, next: 100 });
  const viewImages = useRef();

  const handleScroll = (type, e) => {
    e.preventDefault();
    if (type === "next") {
      viewImages.current.scrollLeft = scrollAction.next;
      setScrollAction({ next: scrollAction.next + 100, prev: 100 });
    } else {
      viewImages.current.scrollLeft = scrollAction.prev;
      setScrollAction({ next: 100, prev: scrollAction.prev - 100 });
    }
  };

  return (
    <div className="galery">
      <button className="btn btn-primary" onClick={addimage} disabled={open}>
        <div onClick={addimage}>Añadir imagen</div>
        <i className="fa-solid fa-plus" />
      </button>

      <div className="prev">
        <button
          className="btn btn-primary width100"
          onClick={(e) => handleScroll("prev", e)}
        >
          <i className="fa-solid fa-angle-left"></i>
        </button>
      </div>
      <div className="images-galery" ref={viewImages}>
        {images &&
          images.map((img, i) => (
            <div className="item-image-galery" key={i}>
              {img !== "temp" ? (
                <i
                  className="fa-solid fa-circle-xmark"
                  onClick={() =>
                    setImages((prevImg) =>
                      prevImg.filter((imgT) => imgT !== img)
                    )
                  }
                ></i>
              ) : (
                <i></i>
              )}
              <img
                src={
                  img !== "temp"
                    ? img
                    : "https://static.thenounproject.com/png/3322766-200.png"
                }
              />
            </div>
          ))}
      </div>

      <div className="next">
        <button
          className="btn btn-primary"
          onClick={(e) => handleScroll("next", e)}
        >
          <i className="fa-solid fa-angle-right"></i>
        </button>
      </div>
    </div>
  );
}

export default AddImages;
