import React, { useContext, useEffect, useRef, useState } from "react";
import "./Style.css";
import * as filestack from "filestack-js";
import UseFireBase from "../../../Hooks/UseFireBase";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import AddImages from "./AddImages";

export default function NewDeliveries() {
  const { uid } = useParams();
  const { add, getData } = UseFireBase();
  const [dataForm, setDataForm] = useState({ ids_products: [] });
  const [listProducts, setListProducts] = useState([]);
  const [imagesBefore, setImagesBefore] = useState([]);
  const [imagesAfter, setImagesAfter] = useState([]);
  const [isOpen, setIsOpen] = useState({ isOpen1: false, isOpen2: false });

  const getDatas = async () => {
    const dataProductos = await getData("products");
    setListProducts(dataProductos);
  };

  const inputOnChange = (e) => {
    if (e.target.checked === true && e.target.name === "id_product") {
      setDataForm({
        ...dataForm,
        ids_products: [...dataForm.ids_products, e.target.value],
      });
    } else if (e.target.checked === false && e.target.name === "id_product") {
      setDataForm({
        ...dataForm,
        ids_products: dataForm.ids_products.filter(
          (idproduct) => idproduct !== e.target.value
        ),
      });
    } else {
      setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    }

    if (
      dataForm.name_wirehouse &&
      dataForm.direction_wirehouse &&
      dataForm.name_wirehouse !== "" &&
      dataForm.direction_wirehouse !== ""
    ) {
      setIsOpen({ ...isOpen, isOpen1: true });
    } else if (
      dataForm.name_wirehouse === "" ||
      dataForm.direction_wirehouse === ""
    ) {
      setIsOpen({ ...isOpen, isOpen1: false });
    }

    if (
      dataForm.name_counter &&
      dataForm.phone_counter &&
      dataForm.name_counter !== "" &&
      dataForm.phone_counter !== ""
    ) {
      setIsOpen({ ...isOpen, isOpen2: true });
    } else if (dataForm.name_counter === "" || dataForm.phone_counter === "") {
      setIsOpen({ ...isOpen, isOpen2: false });
    }
    console.log(dataForm, e.target.value, e.target.checked);
  };

  const formHandleSubmitSupport = (e) => {
    e.preventDefault();
    dataForm.images_before = imagesBefore;
    dataForm.images_After = imagesAfter;
    dataForm.date = new Date().toString("en-US");
    dataForm.id_user = uid;
    console.log(dataForm);
    Swal.fire({
      title: "Confirmar Entrega",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      denyButtonText: `Cancelar`,
      html: `<div>
      <h4>Nombre almacén: ${dataForm.name_wirehouse}<h4>
      <h4>Dirección almacén: ${dataForm.direction_wirehouse}<h4>
      <h4>Nombre mostrador: ${dataForm.name_counter}<h4>
      <h4>Teléfono mostrador: ${dataForm.phone_counter}<h4>
      </div>`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        add("deliveries", dataForm).then(() => {
          Swal.fire("Se ha creado la entrega con exito", "", "success").then(
            () => {
              window.location = "";
            }
          );
        });
      } else if (result.isDenied) {
        Swal.fire("Hubo un Error", "", "info");
      }
    });
  };

  function addimageBefore() {
    const client = filestack.init("AXPqg0v7nQFMn48eQGdgFz");
    client
      .picker({
        onUploadDone: (result) => {
          setImagesBefore([...imagesBefore, result.filesUploaded[0].url]);
        },
      })
      .open();
  }

  function addimageAfter() {
    const client = filestack.init("AXPqg0v7nQFMn48eQGdgFz");
    client
      .picker({
        onUploadDone: (result) => {
          setImagesAfter([...imagesAfter, result.filesUploaded[0].url]);
        },
      })
      .open();
  }
  useEffect(() => {
    getDatas();
  }, []);

  return (
    <div className="principal">
      <div className="box-title">
        <div
          className={isOpen.isOpen1 ? "indicator-active" : "indicator-disable"}
        >
          {isOpen.isOpen1 ? <i className="fa-solid fa-check"></i> : 1}
        </div>
        <h2 className="title-box-delivery">Almacén</h2>
      </div>
      <div className="grid-info">
        <div className="panel">
          <label htmlFor="lang">Nombre</label>
          <input
            type="text"
            name="name_wirehouse"
            placeholder="Escribe el nombre..."
            className="form-control"
            onChange={(e) => {
              inputOnChange(e);
            }}
          ></input>
        </div>
        <div className="panel-1">
          <label htmlFor="lang">Dirección</label>
          <input
            type="text"
            name="direction_wirehouse"
            className="form-control"
            placeholder="Escribe la dirección..."
            onChange={(e) => inputOnChange(e)}
          ></input>
        </div>
        <div></div>
        <div className="buttons-form-products">
          <button
            id="mostrador"
            className="btn btn-primary"
            disabled={!isOpen.isOpen1}
            onClick={() => (window.location.href = "#mostrador")}
          >
            Continuar
          </button>
        </div>
      </div>

      <div className="box-title">
        <div
          className={isOpen.isOpen2 ? "indicator-active" : "indicator-disable"}
        >
          {isOpen.isOpen2 ? <i className="fa-solid fa-check"></i> : 2}
        </div>
        <h2 className="title-box-delivery">Mostrador</h2>
      </div>
      <div className="grid-info">
        <div className="panel-1">
          <label htmlFor="lang">Nombre</label>
          <input
            type="text"
            name="name_counter"
            placeholder="Escribe el nombre..."
            disabled={!isOpen.isOpen1}
            className="form-control"
            onChange={(e) => {
              inputOnChange(e);
            }}
          ></input>
        </div>
        <div className="panel-1">
          <label htmlFor="lang">Teléfono</label>
          <input
            type="text"
            name="phone_counter"
            disabled={!isOpen.isOpen1}
            className="form-control"
            placeholder="Escribe el telefono..."
            onChange={(e) => inputOnChange(e)}
          ></input>
        </div>
        <div></div>

        <div className="buttons-form-products">
          <button
            id="before"
            className="btn btn-primary"
            disabled={!isOpen.isOpen2 || !isOpen.isOpen1}
            onClick={() => (window.location.href = "#before")}
          >
            Continuar
          </button>
        </div>
      </div>
      <div className="box-title">
        <div
          className={
            imagesBefore.length > 0 ? "indicator-active" : "indicator-disable"
          }
        >
          {imagesBefore.length > 0 ? <i className="fa-solid fa-check"></i> : 3}
        </div>
        <h2 className="title-box-delivery">Fotos (antes)</h2>
      </div>
      <div className="info-product">
        <div className="panel">
          <AddImages
            open={!isOpen.isOpen2}
            images={
              imagesBefore.length < 1 ? ["temp", "temp", "temp"] : imagesBefore
            }
            addimage={addimageBefore}
            setImages={setImagesBefore}
          />
          <div className="grid-info mt-25">
            <div></div>
            <div className="btn-continuar">
              <button
                id="products"
                className="btn btn-primary"
                disabled={imagesBefore.length === 0}
                onClick={() => {
                  window.location.href = "#products";
                }}
              >
                Continuar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="box-title">
        <div
          className={
            dataForm.ids_products.length > 0 ? "indicator-active" : "indicator-disable"
          }
        >
          {dataForm.ids_products.length > 0 ? <i className="fa-solid fa-check"></i> : 4}
        </div>
        <h2 className="title-box-delivery">Lista de poductos</h2>
      </div>
      <div className="info-business">
        <div>
          <div>
            {listProducts &&
              listProducts.map((product, i) => (
                <div key={i} className="display-flex">
                  {product.data && product.data.name && product.data.name && (
                    <input
                      type="checkbox"
                      disabled={imagesBefore.length === 0}
                      name="id_product"
                      value={product.id}
                      onChange={(e) => inputOnChange(e)}
                    ></input>
                  )}
                  {product.data && product.data.name && product.data.name}
                </div>
              ))}
          </div>
          <div className="grid-info mt-25">
            <div></div>
            <div className="btn-continuar">
              <button
                id="after"
                disabled={dataForm.ids_products.length === 0}
                className="btn btn-primary"
                onClick={() => {
                  window.location.href = "#after";
                }}
              >
                Continuar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="box-title">
        <div
          className={
            imagesAfter.length > 0 ? "indicator-active" : "indicator-disable"
          }
        >
          {imagesAfter.length > 0 ? <i className="fa-solid fa-check"></i> : 5}
        </div>
        <h2 className="title-box-delivery">Fotos (después)</h2>
      </div>
      <div className="info-product">
        <div className="panel">
          <AddImages
            open={dataForm.ids_products.length === 0}
            images={
              imagesAfter.length < 1 ? ["temp", "temp", "temp"] : imagesAfter
            }
            addimage={addimageAfter}
            setImages={setImagesAfter}
          />
          <div className="grid-info mt-25">
            <div></div>
            <div className="btn-continuar">
              <button
                id="comment"
                disabled={imagesAfter.length === 0}
                className="btn btn-primary"
                onClick={() => {
                  window.location.href = "#comment";
                }}
              >
                Continuar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="box-title">
        <div
          className={
            dataForm.observations ? "indicator-active" : "indicator-disable"
          }
        >
          {dataForm.observations ? (
            <i className="fa-solid fa-check"></i>
          ) : (
            6
          )}
        </div>
        <h2 className="title-box-delivery">Comentarios</h2>
      </div>
      <div className="data-recived">
        <div className="panel-4">
          <div className="Customer-info">
            <textarea
              onChange={(e) => inputOnChange(e)}
              required
              name="observations"
              className="form-control height200px"
              placeholder="Escribe tu comentario..."
              id="floatingTextarea"
              disabled={
                !isOpen.isOpen1 ||
                !isOpen.isOpen2 ||
                imagesAfter.length === 0 ||
                imagesBefore.length === 0 ||
                dataForm.ids_products.length === 0
              }
            ></textarea>
          </div>
          <div className="grid-info mt-25">
            <div></div>
            <div className="btn-continuar">
              <button
                className="btn btn-primary"
                onClick={formHandleSubmitSupport}
                disabled={
                  !isOpen.isOpen1 ||
                  !isOpen.isOpen2 ||
                  imagesAfter.length === 0 ||
                  imagesBefore.length === 0 ||
                  dataForm.ids_products.length === 0
                }
              >
                Registrar entrega
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
