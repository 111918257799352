import { initializeApp } from "firebase/app";
import {
  doc,
  getFirestore,
  collection,
  addDoc,
  getDocs,
  query,
  where,
  updateDoc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { firebaseConfig } from "../Auth";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function UseFireBase() {
  async function add(coleccion, body) {
    try {
      const docRef = await addDoc(collection(db, coleccion), body);
      console.log("Document written with ID: ", docRef.id);
      return docRef.id;
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  async function updated(coleccion, body, id) {
    const ref = doc(db, coleccion, id);
    await updateDoc(ref, body);
  }

  async function getData(coleccion) {
    const querySnapshot = await getDocs(collection(db, coleccion));
    const dataResult = [];
    querySnapshot.forEach((doc) => {
      dataResult.push({ id: doc.id, data: doc.data() });
    });
    return dataResult;
  }
  async function getDataWhere(coleccion, key, value) {
    const q = query(collection(db, coleccion), where(key, "==", value));
    const querySnapshot = await getDocs(q);
    let data;
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data = doc.data();
    });
    return data;
  }
  async function getForId(coleccion, id) {
    const q = query(doc(db, coleccion, id));
    const data = await getDoc(q);
    return data.data();
  }
  async function deleteDocWithId(coleccion, id) {
    await deleteDoc(doc(db, coleccion, id));
  }
  return { add, getData, updated, getDataWhere, deleteDocWithId, getForId };
}

export default UseFireBase;
