import React, { useContext, useState } from "react";
import "./index.css";
import logo from "../../resources/legrand-logo.jpeg";
import UseAuthFirebase from "../../Auth/UseAuthFirebase";
import { dataUserContext } from "../../Context/UserContext";
import { Link } from "react-router-dom";

export default function LateralBar({ select }) {
  const { authLogout } = UseAuthFirebase();
  const [isOpen, setIsOpen] = useState(false);
  const { dataUser } = useContext(dataUserContext);

  try {
    return (
      <div className="pincipal-lateral">
        <button
          className="display-none-desktop btn btn-primary"
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="fa-sharp fa-solid fa-bars"></i>
        </button>
        {isOpen && (
          <div className={"display-none-desktop item-mobile select"}>
            <Link to="/advisor">
              <i className="fa-sharp fa-solid fa-house"></i>
              Asesores
            </Link>

            <Link to="/deliveries">
              <i className="fa-sharp fa-solid fa-users"></i>
              Entregas
            </Link>

            <Link to="/products">
              <i className="fa-sharp fa-solid fa-user"></i>
              Productos
            </Link>

            <div
              id="exit"
              className="display-none-desktop"
              onClick={() => authLogout()}
            >
              <i className="fa-sharp fa-solid fa-arrow-right-from-bracket"></i>
              Salir
            </div>
          </div>
        )}
        <nav className="bar">
          <div className="display-none-mobile">
            <ul>
              <li>
                <div className="logo">
                  <img src={logo} style={{ width: "100%" }} />
                </div>
              </li>
              <li>
                <div className="logo">
                  <h5>
                    Bienvenid@ {dataUser && dataUser.name}{" "}
                    {dataUser && dataUser.lastname}
                  </h5>
                </div>
              </li>

              <li
                className={
                  select === "advisor"
                    ? "display-none-mobile select"
                    : "display-none-mobile"
                }
              >
                <Link to="/advisor">
                  <i className="fa-sharp fa-solid fa-house"></i>
                  Asesores
                </Link>
              </li>

              <li
                className={
                  select === "deliveries"
                    ? "display-none-mobile select"
                    : "display-none-mobile"
                }
              >
                <Link to="/deliveries">
                  <i className="fa-sharp fa-solid fa-users"></i>
                  Entregas
                </Link>
              </li>
              <li
                className={
                  select === "product"
                    ? "display-none-mobile select"
                    : "display-none-mobile"
                }
              >
                <Link to="/products">
                  <i className="fa-sharp fa-solid fa-user"></i>
                  Productos
                </Link>
              </li>
            </ul>
          </div>
          <div
            id="salida"
            className="display-none-mobile"
            onClick={() => authLogout()}
          >
            <i className="fa-sharp fa-solid fa-arrow-right-from-bracket"></i>
            Salir
          </div>
        </nav>
      </div>
    );
  } catch (error) {
    console.log(error);
  }
}
